<template>
  <div>
    <h1 v-if="companyGroup" :class="$style.companyName">会社グループ: {{companyGroup.name}}</h1>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <h2>基本情報</h2>
          </div>
          <div class="card-body">
            <div v-if="companyLoading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <ul v-if="!companyLoading" :class="$style.basicInfoList">
              <li><span :class="$style.infoLabel">グループ名 : </span><span :class="$style.infoValue">{{companyGroup.name}}</span></li>
              <li><span :class="$style.infoLabel">コメント : </span><span :class="$style.infoValue">{{companyGroup.comment}}</span></li>
              <li><span :class="$style.infoLabel">会社数 : </span><span :class="$style.infoValue">{{companyGroup.companies_count}}</span></li>
            </ul>
          </div>
        </section>
      </div>
      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <div v-if="companyGroup" class="pull-right mt-2">
              <div class="d-inline-block">
                <router-link :to="{ name: 'companyGroupCreateTimer', params: { id: companyGroup.id } }">
                  <a-button type="primary" class="ml-3" :class="$style.filledBtn">作成</a-button>
                </router-link>
              </div>
            </div>
            <h2>タイマー</h2>
          </div>
          <div class="card-body">
            <div v-if="companyLoading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!companyLoading">
              <a-list
                item-layout="horizontal"
                :data-source="companyGroupTimers"
                :pagination="false"
              >
                <a-list-item slot="renderItem" slot-scope="item">
                  <a slot="actions">
                    <router-link :to="{ name: 'companyGroupUpdateTimer', params: { id: item.id } }">
                      <a-button type="default" shape="round" icon="edit" style="margin-left: 10px;">編集</a-button>
                    </router-link>
                    <a-button type="default" shape="round" icon="play-circle" style="margin-left: 10px;" @click="executeTimerModalOpen(item.id)">実行</a-button>
                  </a>
                  <a-list-item-meta
                    :description="'【対応会社】' + item.handled_agent_companies_string"
                  >
                    <p slot="title" class="list-name">
                      <a-tag :color="item.status === 1 ? 'blue' : 'red'">{{ item.status === 1 ? '有効' : '無効' }}</a-tag>
                      {{ item.time.substring(0,5) }}
                    </p>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </div>
          </div>
        </section>
      </div>
      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <div v-if="companyGroup" class="pull-right mt-2">
              <div class="d-inline-block">
                <router-link :to="{ name: 'companyGroupManageCompany', params: { id: companyGroup.id } }">
                  <a-button type="primary" class="ml-3" :class="$style.filledBtn">管理</a-button>
                </router-link>
              </div>
            </div>
            <h2>所属会社と現在の点呼代行対応会社</h2>
          </div>
          <div class="card-body">
            <div v-if="companyLoading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!companyLoading">
              <a-table
                :columns="columns"
                :data-source="companyGroupCompanies"
                :pagination="true"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe0 :  $style.stripe1"
              >
                <span slot="name" slot-scope="name, record">{{ record.id }}: {{ record.name }}</span>
              </a-table>
            </div>
          </div>
        </section>
      </div>
      <a-modal
        v-model="executeTimerModalVisible"
        title="タイマー実行"
        :okText="'実行'"
        :cancelText="'キャンセル'"
        :confirmLoading="executeTimerLoading"
        @ok="handleExecuteTimerOk"
      >
        <p>このタイマーを実行してもよろしいですか？</p>
        <p v-if="executeTimer">このグループ内にあるすべての会社の点呼代行対応会社を以下もののみに設定する。<br><br>{{ executeTimer.handled_agent_companies_string }}</p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      companyLoading: true,
      companyGroupTimerLoading: true,
      handleRollCallAgentCompanyloading: true,
      companyGroup: null,
      companyGroupCompanies: [],
      handledCompanies: [],
      companyGroupTimers: [],
      executeTimerModalVisible: false,
      executeTimerLoading: false,
      executeTimer: null,
    }
  },
  computed: {
    columns() {
      const baseColumns = [
        {
          title: '所属会社名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
      ]
      const handledCompanyColumns = this.handledCompanies.map(company => ({
        title: company.name,
        dataIndex: `handled_company_${company.id}`,
        customRender: (text, record) => {
          return record.handle_agent_companies.some(c => c.id === company.id) ? ' O ' : ''
        },
      }))
      return [...baseColumns, ...handledCompanyColumns]
    },
  },
  created() {
    const result = Vue.prototype.$api.send('get', 'company_groups/' + this.$route.params.id)
    result.then(response => {
      this.companyGroup = response
      this.companyLoading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社グループの取得に失敗しました。',
        })
        this.companyLoading = false
      })

    this.getCompanyGroupCompanies()

    const handleCompanyResult = Vue.prototype.$api.send('get', 'master/handled_companies')
    handleCompanyResult.then(response => {
      this.handledCompanies = response
      this.handleRollCallAgentCompanyloading = false

      const companyGroupTimer = Vue.prototype.$api.send('get', 'company_groups/' + this.$route.params.id + '/timers')
      companyGroupTimer.then(response => {
        this.companyGroupTimers = response.map(timer => {
          const handleAgentCompanyArray = timer.handle_agent_company_array.split(',').map(Number)
          const handledCompanies = this.handledCompanies.filter(company => handleAgentCompanyArray.includes(company.id))
          const handledAgentCompaniesString = handledCompanies.map(company => company.name).join(', ')
          timer.handled_agent_companies_string = handledAgentCompaniesString
          return timer
        })
        this.companyGroupTimers.sort((a, b) => {
          return a.time.localeCompare(b.time)
        })
        this.companyGroupTimerLoading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 会社グループのタイマーの取得に失敗しました。',
          })
          this.companyGroupTimerLoading = false
        })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 点呼代行受付会社一覧の取得に失敗しました。',
        })
        this.loading = false
      })
  },
  methods: {
    getCompanyGroupCompanies() {
      const companyList = Vue.prototype.$api.send('get', 'company_groups/' + this.$route.params.id + '/companies')
      companyList.then(response => {
        this.companyGroupCompanies = response
        this.companyLoading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 会社グループに所属している会社の取得に失敗しました。',
          })
          this.companyLoading = false
        })
    },
    executeTimerModalOpen(id) {
      this.executeTimerModalVisible = true
      this.executeTimer = this.companyGroupTimers.find(timer => timer.id === id)
    },
    handleExecuteTimerOk() {
      Vue.prototype.$api.send('post', 'company_groups/execute_timer/' + this.executeTimer.id)
        .then(response => {
          this.getCompanyGroupCompanies()
          this.$notification['success']({
            message: 'タイマーを実行しました。',
          })
          this.executeTimerModalVisible = false
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': タイマーの実行に失敗しました。',
          })
          this.executeTimerModalVisible = false
        })
    },
  },
}
</script>
<style lang="scss" module>
@import "../style.module.scss";
.list-name {
  font-size: 1.5rem;
}
</style>
