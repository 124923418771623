var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.companyGroup
      ? _c("h1", { class: _vm.$style.companyName }, [
          _vm._v("会社グループ: " + _vm._s(_vm.companyGroup.name))
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("section", { staticClass: "card" }, [
            _vm._m(0),
            _c("div", { staticClass: "card-body" }, [
              _vm.companyLoading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              !_vm.companyLoading
                ? _c("ul", { class: _vm.$style.basicInfoList }, [
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("グループ名 : ")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(_vm._s(_vm.companyGroup.name))
                      ])
                    ]),
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("コメント : ")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(_vm._s(_vm.companyGroup.comment))
                      ])
                    ]),
                    _c("li", [
                      _c("span", { class: _vm.$style.infoLabel }, [
                        _vm._v("会社数 : ")
                      ]),
                      _c("span", { class: _vm.$style.infoValue }, [
                        _vm._v(_vm._s(_vm.companyGroup.companies_count))
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "col-lg-12" }, [
          _c("section", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm.companyGroup
                ? _c("div", { staticClass: "pull-right mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "d-inline-block" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "companyGroupCreateTimer",
                                params: { id: _vm.companyGroup.id }
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticClass: "ml-3",
                                class: _vm.$style.filledBtn,
                                attrs: { type: "primary" }
                              },
                              [_vm._v("作成")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("h2", [_vm._v("タイマー")])
            ]),
            _c("div", { staticClass: "card-body" }, [
              _vm.companyLoading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              !_vm.companyLoading
                ? _c(
                    "div",
                    [
                      _c("a-list", {
                        attrs: {
                          "item-layout": "horizontal",
                          "data-source": _vm.companyGroupTimers,
                          pagination: false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "renderItem",
                              fn: function(item) {
                                return _c(
                                  "a-list-item",
                                  {},
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { slot: "actions" },
                                        slot: "actions"
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "companyGroupUpdateTimer",
                                                params: { id: item.id }
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "a-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                },
                                                attrs: {
                                                  type: "default",
                                                  shape: "round",
                                                  icon: "edit"
                                                }
                                              },
                                              [_vm._v("編集")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            },
                                            attrs: {
                                              type: "default",
                                              shape: "round",
                                              icon: "play-circle"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.executeTimerModalOpen(
                                                  item.id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("実行")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-list-item-meta",
                                      {
                                        attrs: {
                                          description:
                                            "【対応会社】" +
                                            item.handled_agent_companies_string
                                        }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "list-name",
                                            attrs: { slot: "title" },
                                            slot: "title"
                                          },
                                          [
                                            _c(
                                              "a-tag",
                                              {
                                                attrs: {
                                                  color:
                                                    item.status === 1
                                                      ? "blue"
                                                      : "red"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.status === 1
                                                      ? "有効"
                                                      : "無効"
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  item.time.substring(0, 5)
                                                ) +
                                                "\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          1204605897
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "col-lg-12" }, [
          _c("section", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm.companyGroup
                ? _c("div", { staticClass: "pull-right mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "d-inline-block" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "companyGroupManageCompany",
                                params: { id: _vm.companyGroup.id }
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticClass: "ml-3",
                                class: _vm.$style.filledBtn,
                                attrs: { type: "primary" }
                              },
                              [_vm._v("管理")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("h2", [_vm._v("所属会社と現在の点呼代行対応会社")])
            ]),
            _c("div", { staticClass: "card-body" }, [
              _vm.companyLoading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              !_vm.companyLoading
                ? _c(
                    "div",
                    [
                      _c("a-table", {
                        attrs: {
                          columns: _vm.columns,
                          "data-source": _vm.companyGroupCompanies,
                          pagination: true,
                          rowKey: "id",
                          rowClassName: function(r, i) {
                            return i % 2 === 0
                              ? _vm.$style.stripe0
                              : _vm.$style.stripe1
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "name",
                              fn: function(name, record) {
                                return _c("span", {}, [
                                  _vm._v(
                                    _vm._s(record.id) +
                                      ": " +
                                      _vm._s(record.name)
                                  )
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          1729136279
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ]),
        _c(
          "a-modal",
          {
            attrs: {
              title: "タイマー実行",
              okText: "実行",
              cancelText: "キャンセル",
              confirmLoading: _vm.executeTimerLoading
            },
            on: { ok: _vm.handleExecuteTimerOk },
            model: {
              value: _vm.executeTimerModalVisible,
              callback: function($$v) {
                _vm.executeTimerModalVisible = $$v
              },
              expression: "executeTimerModalVisible"
            }
          },
          [
            _c("p", [_vm._v("このタイマーを実行してもよろしいですか？")]),
            _vm.executeTimer
              ? _c("p", [
                  _vm._v(
                    "このグループ内にあるすべての会社の点呼代行対応会社を以下もののみに設定する。"
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    _vm._s(_vm.executeTimer.handled_agent_companies_string)
                  )
                ])
              : _vm._e()
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("基本情報")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }